import { versionCookie } from '@libs/client/helpers/constant'
import { NextFunction, Request, Response } from 'express'
import { excluded, parseCookie } from '../ultilities'
import { store_type } from '@libs/common/models/constant'
import { page_versions_traffic } from '@libs/common'

export const redirect = async (req: Request, res: Response, next: NextFunction) => {
  try {
    const subpath = req?.url.split('?').length ? req?.url.split('?')[0] : ''
    if (subpath?.includes('//')) {
      res.statusCode = 400
      res.send('BadRequest')
      return
    }
    if (req?.url && !excluded(req?.url)) {
      const host = req.hostname
      const stardardHost = host.replace(/^http:/g, '^https:').replace(/www\./g, '')
      if (host != stardardHost) {
        res.redirect(`https://${stardardHost}${req?.url ?? ''}`)
        return
      } else {
        if (!req?.url.includes('api/')) {
          const previewContentVersion = req?.query?.['--content-version']?.toString()
          const cookieClient = parseCookie(req?.headers?.cookie || '')
          const userCookie = cookieClient?._version_
          const store_id = process._repository?.domains?.[stardardHost]?.store_id || ''
          const store = process._repository?.stores?.[store_id]
          const publisher =
            process._repository.publishers?.[store?.setting?.settings?.general?.publisher_id || '']
          const slugOnly =
            store?.setting?.settings?.general?.type === store_type.SELLPAGE
              ? ''
              : Object.keys(store?.pages || {}).find((slug) => subpath.split('/').includes(slug)) ||
                ''
          const pageId =
            store?.setting?.settings?.general?.type === store_type.SELLPAGE
              ? store?.pages?.setting?.settings?.general?.id
              : store?.pages?.[slugOnly || '']?.setting?.settings?.general?.id
          const key = `pversion${slugOnly ? `_${slugOnly}` : ''}`

          const pVersionCookie = cookieClient?.[key]
          if (!userCookie) {
            const traffic = process._repository.default_store?.setting?.settings?.general?.traffic
            let cookieValue = 'v144'
            if (traffic?.patterns) {
              const urlRequest = `${stardardHost}${subpath == '/' || subpath == '' ? '' : subpath}`
              // filter bot facebook and traffic from homepage multistore
              if (
                req?.socket?.remoteAddress?.includes('face') ||
                isFromHomepage(store?.setting?.settings?.general?.type, slugOnly)
              ) {
                cookieValue = 'v144'
              } else {
                const pattern = (traffic?.patterns || []).find((x: S3Types.spit_traffic) => {
                  const dataPattern = x?.match?.split('|')
                  const trafficDomain = dataPattern?.[1]?.split(',')
                  return (
                    dataPattern.length > 1 &&
                    (dataPattern?.includes(publisher?.email || '') ||
                      dataPattern?.includes(publisher?.type || '') ||
                      trafficDomain.some((x) => urlRequest.includes(x)))
                  )
                })

                // get config split verion from CMS admin
                const versionSplit = pattern
                  ? pattern.split
                  : traffic?.patterns[traffic.patterns.length - 1].split // get traffic to last item config admin
                const allVersionConfigAdmin = Object.keys(versionSplit || {})

                if (
                  process.cacheServer &&
                  process.cacheServer?.[urlRequest] &&
                  Object.keys(process.cacheServer?.[urlRequest]).every((version) =>
                    allVersionConfigAdmin.includes(version)
                  )
                ) {
                  const sumTraffic =
                    Object.values(process.cacheServer?.[urlRequest] || {})?.reduce(
                      (partialSum, item) => partialSum + item,
                      0
                    ) || 1 // sum of all traffic in this domain

                  const shouldVersion: string =
                    allVersionConfigAdmin?.find((versionKey) => {
                      const numberAtVersion = process.cacheServer?.[urlRequest]?.[versionKey] ?? 0
                      return versionSplit[versionKey] >= numberAtVersion / sumTraffic
                    }) || ''
                  process.cacheServer[urlRequest][shouldVersion]++
                  cookieValue = shouldVersion
                } else {
                  const chooseVersion = choiceVersionByRandomer(versionSplit)
                  const initFirstCache = Object.keys(versionSplit || {})?.reduce(
                    (acc, val) => ({ ...acc, [val]: val == chooseVersion ? 1 : 0 }),
                    {}
                  )
                  process.cacheServer = { ...process.cacheServer, [urlRequest]: initFirstCache }
                  cookieValue = chooseVersion || 'v144'
                }
              }
            }

            cookieValue = versionCookie?.includes(cookieValue) ? cookieValue : 'v144'
            req.headers.cookie += `; _version_=${cookieValue}`
            res.cookie('_version_', cookieValue)
            res.setHeader('version', cookieValue)
          } else {
            if (userCookie && versionCookie?.includes(userCookie)) {
              res.setHeader('version', userCookie)
            } else {
              req.headers.cookie += `; _version_=v144`
              res.cookie('_version_', 'v144')
              res.setHeader('version', 'v144')
            }
          }

          // start spliting content version
          if (previewContentVersion) {
            req.headers.cookie += `; ${key}=${previewContentVersion}`
            res.cookie(key, previewContentVersion)
            res.setHeader(key, previewContentVersion)
          } else {
            if (!process.env?.AB_CONTENT_ACC?.includes(publisher?.email || '')) {
              const page = process._repository.pages?.[pageId || '']
              const listVersion = page?.setting?.settings?.traffic_config || {}
              const allVersionConfigPageVersion = Object.keys(listVersion || {})
              if (!pVersionCookie || !pVersionCookie.includes(pageId || '')) {
                let pageVersion = allVersionConfigPageVersion?.[0] || ''
                if (
                  process.cachePageVersion &&
                  process.cachePageVersion?.[pageId || ''] &&
                  Object.keys(process.cachePageVersion?.[pageId || '']).every((version) =>
                    Object.values(allVersionConfigPageVersion).includes(version)
                  )
                ) {
                  const shouldVersion = getShouldVersion(
                    allVersionConfigPageVersion,
                    pageId || '',
                    listVersion
                  )
                  process.cachePageVersion[pageId || ''][shouldVersion]++
                  pageVersion = shouldVersion
                } else {
                  const chooseVersion = choiceVersionByRandomer(listVersion, true)
                  const initFirstCache = Object.keys(listVersion || {})?.reduce(
                    (acc, val) => ({ ...acc, [val]: val == chooseVersion ? 1 : 0 }),
                    {}
                  )
                  process.cachePageVersion = {
                    ...process.cachePageVersion,
                    [pageId || '']: initFirstCache
                  }
                  pageVersion = chooseVersion || ''
                }
                req.headers.cookie += `; ${key}=${pageVersion}`
                res.cookie(key, pageVersion)
                res.setHeader(key, pageVersion)
              } else {
                if (
                  allVersionConfigPageVersion?.length == 2 &&
                  cookieClient?.['current_config'] != allVersionConfigPageVersion?.join('-')
                ) {
                  const reqCurrentCookie = cookieClient?.['current_config']?.split('-')
                  const isDiffirenceConfig = checkIsTheSameConfig(
                    allVersionConfigPageVersion,
                    reqCurrentCookie,
                    pVersionCookie
                  )
                  if (isDiffirenceConfig) {
                    const chooseVersion = choiceVersionByRandomer(listVersion, true)
                    const initFirstCache = Object.keys(listVersion || {})?.reduce(
                      (acc, val) => ({ ...acc, [val]: val == chooseVersion ? 1 : 0 }),
                      {}
                    )
                    process.cachePageVersion = {
                      ...process.cachePageVersion,
                      [pageId || '']: initFirstCache
                    }
                    req.headers.cookie += `; ${key}=${chooseVersion}`
                    res.cookie(key, chooseVersion)
                    res.setHeader(key, chooseVersion)
                    res.cookie('is_clear_cart', 'true')
                  } else {
                    res.setHeader(key, pVersionCookie)
                  }
                } else {
                  res.setHeader(key, pVersionCookie)
                }
              }
              res.cookie('current_config', `${allVersionConfigPageVersion?.join('-')}`) // example: '55zv9i9kpjttrws8dg12vrh0m_0-55zv9i9kpjttrws8dg12vrh0m_1'
            }
          }
        }
      }
    }
    next()
  } catch (error) {
    console.error(error, req.url, req?.headers)
  }
}

const choiceVersionByRandomer = (split: { [key: string]: number }, isVersion = false) => {
  const randomPercent = isVersion ? Math.random() * 100 : Math.random()
  let percentValue = 0
  const choiceVersion =
    Object.entries(split || {}).find(([key, value]) => {
      percentValue += +(value || '0')
      return key && randomPercent < percentValue
    })?.[0] || (isVersion ? '' : 'v144')

  return choiceVersion
}

const isFromHomepage = (type: store_type | undefined, subpath: string) => {
  return type === store_type.MINISTORE && (subpath == '/' || subpath == '')
}

const checkIsTheSameConfig = (configCMS: string[], currentConfig: string[], version: string) => {
  if (!configCMS || !currentConfig) return false
  // version must be included configCMS and currentConfig
  const isDiffirenceConfig =
    configCMS.some((x) => x == version) && currentConfig.some((x) => x == version)
  return isDiffirenceConfig
}

const getShouldVersion = (
  allVersionConfigPageVersion: string[],
  pageId: string,
  listVersion: page_versions_traffic
) => {
  const sumTraffic =
    Object.values(process.cachePageVersion?.[pageId || ''] || {})?.reduce(
      (partialSum, item) => partialSum + item,
      0
    ) || 1 // sum of all traffic in this domain

  const shouldVersion: string =
    allVersionConfigPageVersion?.find((versionKey) => {
      const numberAtVersion = process.cachePageVersion?.[pageId || '']?.[versionKey] ?? 0
      return listVersion[versionKey] >= (numberAtVersion * 100) / sumTraffic
    }) || ''
  return shouldVersion
}
