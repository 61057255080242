const isOneOf = (domain: string, ...domainList: string[]) => {
  return domainList.some((d) => domain.includes(d))
}

export const hardcodePaygateContact = (domain: string): Partial<S3Types.paygate_contact> => {
  if (isOneOf(domain, 'testshop.onselless.dev', 'cartinfo-v12nd.onselless.dev', 'wingbras.com')) {
    return {
      phone: ' +1 (877) 715-8775',
      email: 'support@wingbras.com'
    }
  }
  return {}
}

export const hardcodeSellpage = (domain: string, page: S3Types.page) => {
  if (isOneOf(domain, 'carlacharm.com', 'jessiebra.com')) {
    if (page?.setting?.settings?.general?.title) {
      page.setting.settings.general.title = page.setting.settings.general.title.replace(
        /^UpWing/,
        'Jessie'
      )
    }
  }
}

export const isDomainHardcodeForBvf = (domain: string) => {
  return isOneOf(
    domain || (process.browser ? window?.location?.hostname : ''),
    'testshop.onselless.dev',
    'cartinfo-v12nd.onselless.dev',
    'wingbras.com'
  )
}

export const isOrderInUserSizingConfig = (product_line: string) => {
  const isPrdLineApplyForAll = ['BRA', 'PANT', 'T-SHIRT', 'DRESS', 'PANTIES'] //Need check real product line data at backend
  if (isPrdLineApplyForAll.includes(product_line)) {
    return true // Not including BVF
  }
  return false
}

export const hardcodeStaticPageForBvf = (
  domain: string
): { [static_slug: string]: S3Types.default_store_static_page } => {
  if (isDomainHardcodeForBvf(domain)) {
    return {
      'return-policy': {
        title: 'Exchanges & Returns',
        items: [
          {
            title: 'What is {{ shop }}’s exchange and return policy?',
            contents: [
              'We offer a 45-Day Fit Guarantee. If you aren’t totally in love with how you feel or fit on {{ shop }}’s product, exchange or ask for a refund within <b>45 days</b> from your date of purchase. '
            ]
          },
          {
            title: 'How does {{ shop }}’ exchange service work?',
            contents: [
              'When you need a different style, size, or color, we’ve got you covered 100%! If you paid shipping on your original order, exchanges are on us, at no extra cost to you. '
            ]
          },
          {
            title: 'How does {{ shop }}’ return and refund service work?',
            contents: [
              'Just want your money back? No worries, returns are simple. Just send your item back, submit your proof of return shipping, and we’ll immediately refund your purchase.',
              'Money back refunds are offered when your request is made <b>30 days</b> from your <b>date of purchase</b>. Between <b>31 – 45 days</b> from date of purchase, we will be happy to provide you with an in-store credit for the total amount, including shipping. In-store credit <b>expires 60 days</b> from the <b>date of issue</b>.'
            ]
          },
          {
            title: 'Who pays for return shipping?',
            contents: [
              'If you have paid for shipping on your original purchase, and are returning items for exchanges, we will cover return shipping.',
              'If the purchased item(s) is confirmed to be faulty by our team, we will cover return shipping.',
              'If the original purchase was promoted with free shipping, and you are returning the items for exchanges, we ask that you cover the return shipping.'
            ]
          },
          {
            title: 'What are conditions for exchanges or refunds?',
            contents: [
              'Returned item(s) for exchanges, replacements, or refunds, must be in brand new condition in which they are received. Items damaged due to normal wear and tear, or through misuse will not be considered faulty. {{ shop }} reserve the right to refuse refunds on these grounds.',
              'Items like panties/socks/inner-wear/bra-pads/shapewear are non-returnable due to hygiene conditions. However, in the unlikely event of a damaged, defective, or different item being delivered to you, we will provide a refund or replacement as applicable.'
            ]
          },
          {
            title: 'How do I request an exchange or refund?',
            contents: [
              'Use {{ shop }}’ <a href="/contact" style="text-decoration: none">contact portal</a> to submit your request of choice – exchanges, replacements, or returns and refunds. You will need the order number and email address you registered at checkout. <a href="/static/shipping-policy"  style="text-decoration: none">Check here</a> to see how to find your order number.',
              'Follow guided steps through our service portal to submit your request. Our customer support will contact you within 24hrs to assist you.',
              'For exchange, our sizing expert will help you select the perfect fit before sending the replacement.'
            ]
          },
          {
            title: 'How long will it take for my exchange or refund to be processed?',
            contents: [
              '<b>For exchanges:</b> Once we’ve confirmed the correct item to be replaced, your replacement  will be processed within 2-3 working days and shipped out through your original shipping method.',
              "<b>For refunds:</b> It takes 5-10 business days for your refund to reflect on your account. If you do not see the refund back in your account after ten days, we recommend you contact your issuing bank first. Contact us immediately if your card issuer says they don't see any refund."
            ]
          },
          {
            title: 'Can I change or cancel my order?',
            contents: [
              'Orders are automatically processed within one (1) hour from the time of order confirmation.',
              'To cancel or make changes to your order, submit your request within one (1) hour from the time of order confirmation via our <a href="/contact"  style="text-decoration: none">Contact form</a>',
              'Our team is committed to getting your products out to you as fast as possible. If your order is already processed, we suggest you try the item out first, and if for any reason it doesn’t measure up, we’ll send you a FREE REPLACEMENT.'
            ]
          }
        ]
      },
      'shipping-policy': {
        title: 'Order and Shipping',
        items: [
          {
            title: 'How do I find my order number?',
            contents: [
              'Your order number will be in the subject line of your confirmation email. It’ll look something like this #<b>5881-3783.</b>',
              'If you’re not seeing it in your inbox, try checking to see if it went to your spam folder or search the words “Order confirmation” sent from <span  style="text-decoration: none; color: rgb(45, 156, 219);>{{ email }}</span>, more often than not, it’ll be in there! Once you find our email, move it to your inbox or mark it as safe to easily locate our emails next time.',
              "If you aren’t able to find your order confirmation email, go to the <a href='/trackings/search' style='text-decoration: none'>Track My Order</a> page and select “How to find my order” then click “ Help, I cannot find my order number”. Our customer support will get back to you within 24hrs to help you locate the order in our system."
            ]
          },
          {
            title: 'Where is my order shipped from?',
            contents: [
              'The {{ shop }} team works with top manufacturers worldwide to bring you the best in value for your hard earned dollar! Orders may be shipped from within the continental USA, or other warehouses in Vietnam, India, China, and other regions. We partner with USPS, DHL, and Purolator to deliver your packages.'
            ]
          },
          {
            title: 'How long does it take for the order to reach me?',
            contents: [
              'You will typically receive your order within 5-15 business days from the date of purchase. In fact, 92.5% of our orders are delivered well before the 15 business days benchmark. Delays can sometimes happen during high season, local work stoppages, or because we need to hold your order to confirm shipping address.',
              'We will ensure to communicate with you at every step of the way. From order confirmation, tracking number availability, status updates, to delivery of your package. Please monitor your email and mark messages from {{ shop }} as safe to ensure they land into your inbox.'
            ]
          },
          {
            title: 'How can I track my order?',
            contents: [
              "<b>Using our website:</b> Easily track your order by going to {{ shop }}’ <a href='/trackings/search' style='text-decoration: none'>Track My Order</a> page and input your email address (provided to us at time of purchase), and your order number. Note that one order number may have multiple tracking numbers.",
              '<b>Links in your email:</b> We will send Tracking numbers when they are assigned to your packages. Check your mailbox for emails under the subjects: <b>“Shipping Confirmation” or “Order is on the way”</b>. You may click on the link in this email to track your order and shipping status.',
              'If you need to find your order number, follow this article “How do I find my order number” above.'
            ]
          },
          {
            title: 'What do the Tracking Statuses mean?',
            contents: [
              '<ul style="list-style: disc; margin: 0px 20px; padding: unset;"><li><b>Confirmed</b>: Your order is successfully placed. At this status, you have 1 hours to cancel or make any changes to colors or size.</li><li><b>Processing</b>: We are preparing your order. It will take 2-3 days, and you cannot make any changes to your order.</li><li><b>Shipped</b>: Your order is on its way to you. You will receive an email notification with a tracking link and can start tracking its journey until it reaches your door</li><li><b>Delivered</b>: Your order has been delivered to the shipping address provided upon purchase.</li><li><b>Undeliverable</b>: In rare cases, you will see an undeliverable status which requires your attention. Please check shipping log for the following:<ul style="list-style: inside; margin: unset; padding: unset;"><li style="list-style-type: circle;"><b>Available for pickup</b> – You may collect the package from the specific USPS Facility or the Post Office.</li><li style="list-style-type: circle;"><b>Return to sender, Insufficient Address, Failed delivery attempts</b> – Couriers make 2 or 3 attempts to deliver your package. Contact the courier or shipping provider ASAP - they’ll usually resend your package the next workday or ask you to pick it up at their offices. If it is returned to us, <a href="/contact" style="text-decoration: none">contact us</a> so that we can resend it.</li></ul></li></ul>'
            ]
          },
          {
            title: 'What payment methods does {{ shop }} accept?',
            contents: [
              '<div className="payment-method" style="display: flex;justify-content: center;"><img src="https://cdn.selless.dev/t/d/i/paypal.svg" style="width: 70px; margin-right: 30px;" alt="paypal" /><img src="https://cdn.selless.dev/t/d/i/amex.svg" style="width: 70px; margin-right: 15px;" alt="amex" /><img src="https://cdn.selless.dev/t/d/i/discover.svg" style="width: 70px; margin-right: 15px;" alt="discover" /><img src="https://cdn.selless.dev/t/d/i/master.svg" style="width: 70px; margin-right: 15px;" alt="master" /><img src="https://cdn.selless.dev/t/d/i/visa.svg" style="width: 70px; margin-right: 15px;" alt="visa" /></div>',
              '<ul><li>We currently accept PayPal, Credit and Debit cards (VISA, Mastercard, Discovery, American Express).</li><li>When you place an order with Paypal, you will be redirected to the PayPal payment page where you are required to log on to your Paypal account with your username and password.</li></ul>'
            ]
          },
          {
            title: "My payment won't go through, what should I do?",
            contents: [
              '<b>If you’re unable to pay with your debit or credit card, check the following:</b></br>',
              '1. Make sure all information is correct including the credit card number, expiration date, CVV code and billing address.',
              '2. Check your credit or debit account balance for available funds.',
              '3. Check whether your card is authorized for online purchases, and if the default online transaction limit is too low.',
              '4. Try refreshing the page or logging completely out and then logging back in.',
              '5. Start over with a new order instead of trying to pay for the same order over again.',
              '6. If your payment is still declined, you may need to contact your bank. As part of their security, most credit card companies will automatically block international transactions or payments unless the card holders specifically request that they accept the transaction.',
              '7. Pay with your credit or debit card through Paypal. If you do not have a Paypal account, you can safely and easily use the services as a "guest".',
              '8. Try using a different card.',
              '9. Try through PayPal if you have a PayPal account. </br>',
              '<b>If you‘re unable to pay with Paypal</b>',
              '1. Check with Paypal to see if your account has a transaction limit.',
              '2. According to PayPal rules, the shipping address and the billing address must be in the same country, otherwise the payment will be declined.',
              '3. If Paypal is still not working, please try payment with a debit or credit card (Visa, MasterCard, Discover, and American Express).'
            ]
          },
          {
            title: 'Are sales tax included in your prices?',
            contents: [
              'Yes, if they apply to you.',
              'Depending on where you live, and based on the shipping zip code provided, our system will check for applicable sales tax regulations. If taxes are required to be collected, our system will calculate the applicable amount, collect it, and submit it to your local authority.',
              'Our system is continuously updated with the latest sales tax policies. If tax free holidays are applied to you, our system will also recognize them and not collect tax. However, if you believe sales tax is collected in error, please <a href="/contact" style="text-decoration: none">contact us</a>. We will investigate and refund you where applicable.',
              '{{ shop }} online conducts retail sales only. If you own a business and intend to resale our products, your system will still calculate sales tax, and you are responsible for claiming any tax exempt through your business filings.'
            ]
          }
        ]
      }
    }
  }
  return {}
}

export const hardcodeStaticPage = (
  domain: string
): {
  [static_slug: string]: S3Types.default_store_static_page
} => {
  if (isDomainStaticPageCrossian(domain)) {
    return {
      'about-us': {
        title: 'About Us',
        items: [
          {
            title: '',
            contents: [
              'Welcome to <span class="shop__name">{{ shop }}</span>,',
              '<span class="shop__name">{{ shop }}</span> is a brand owned by the company Crossian LLC. We bring to the specialty boutique, offering a small number of handful tools, accessories and seasonal items that we have thoroughly sourced and stand behind their quality. We focus on products that everyone could use, at best-valued pricing. Select one of our products below to find out more!',
              'Contact The Simple Marketing at: +85510324265 <br /> The Simple Marketing address: 18/F Canadia Bank Tower, No. 315, Ang Doung Street, Corner Monivong Blvd., Village 1, Sangkat Wat Phnom, Khan Daun Penh, Phnom Penh, 120211',
              'Contact Crossian LLC at: +1 (302) 550-9538 <br /> Crossian LLC address: 16192 Coastal Highway, Lewes, Delaware'
            ]
          }
        ]
      }
    }
  }
  if (isDomainStaticPageSimpleMarketing(domain)) {
    return {
      'about-us': {
        title: 'About Us',
        items: [
          {
            title: '',
            contents: [
              'Welcome to our <span class="shop__name">{{ shop }}</span>! <span class="shop__name">{{ shop }}</span> belongs to The Simple Marketing Co., ltd company. Our mission is to provide a convenient experience for our customers. We proud to bring you a small selection of tools, accessories and seasonal items that we have thoroughly sourced and stand behind for their quality. If you have any questions about this store or the products found within, please don`t hesitate to contact us any time.',
              'Contact The Simple Marketing at: +85566653563 <br> The Simple Marketing address: Road No. 1, Voat Phnum, Doun Penh, Phnom Penh, Cambodia'
            ]
          }
        ]
      }
    }
  }
  if (isDomainStaticKYC(domain)) {
    return {
      'about-us': {
        title: 'About Us',
        items: [
          {
            title: '',
            contents: [
              'Welcome to <span class="shop__name">{{ shop }}</span>,',
              '<span class="shop__name">{{ shop }}</span> is a brand owned by the company Crossian LLC. We bring to the specialty boutique, offering a small number of handful tools, accessories and seasonal items that we have thoroughly sourced and stand behind their quality. We focus on products that everyone could use, at best-valued pricing. Select one of our products below to find out more!',
              'Contact Crossian LLC at: +84 93 610 29 88 <br> Crossian LLC address: 16192 Coastal Highway, Lewes, Delaware, 19958'
            ]
          }
        ]
      }
    }
  }
  return {}
}

export const customConfig = (domain: string, subpath: string) => {
  if (isOneOf(domain, 'nasboutiques.com', 'mychloebra.com')) {
    return {
      pixelShow: 'fast',
      isProductPage: isProductPage(subpath)
    }
  }
  return {}
}

const isProductPage = (subpath: string) => {
  return !([
    'checkout',
    'thankyou',
    'orders',
    'contact',
    'trackings',
    'cs-notify',
    'static'
  ] as any[]).includes(subpath)
}

export const isDomainStaticPageCrossian = (domain?: string) => {
  return isOneOf(
    domain || (process.browser ? window?.location?.hostname : ''),
    'noerror.onselless.dev',
    'venuscharm.us',
    'wingslove.us',
    'blingystore.us',
    'rosiaa.com', //
    'hansihandy.com', //
    'pinkchloe.com', //

    'lavelbeauty.us',
    'irisboutique.us',
    'violetcharm.us',
    'sklingerie.us',
    'avasecret.us',

    'coutora.com', //14/6/2023
    'fabalia.com',
    'chiceti.com',
    'bevashop.com',
    'elalift.com',
    'monocharm.com', // update content 12/14/2023 Link: https://crossian.slack.com/archives/C01B17QGTB6/p1702465060210819
    'eleganchic.com', //
    'bloomydove.com', //
    'calormas.com', //
    'loomtext.com', //
    'denimblack.com', //
    'maidenfit.com', //
    'lilasha.com', //
    'truecups.com', //
    'bevawear.com', //
    'venuscharm.us',
    'maracharm.com', //
    'jetjeans.us',
    'bloomypink.com',
    'comfysfit.com',
    'lisacharm.com',
    'wmstylist.com',
    'rosiedelight.com',
    'bluechicstores.us',
    'misatailor.com',
    'mellycharm.com',

    'jollywish.us', // 1/3/2024 https://crossian.slack.com/archives/C01B17QGTB6/p1709279148771949
    'miracharm.com',
    'texwix.com',
    'missboutiques.us',
    'emilycharm.com',
    'miacharm.com',
    'foxyseniors.com',

    'coolmance.us', // 20/02/2024 https://crossian.slack.com/archives/C01B17QGTB6/p1710906903972009
    'puliam.com',
    'lowenstore.com',
    'unwaverin.com',

    'lynsiecharm.com', //28/03/2024
    'ellydove.com', // 19/04
    'judewest.com',
    'luxelana.com',
    'mencomfy.com',
    'gentyfit.com',
    'glamlacy.com',
    'bluecomfy.com',
    'manzera.com',
    'lalachics.com',
    'eragirl.com',
    'vintibloom.com',
    'coolflex.us',

    'lanenow.com', // 25/4

    'amazinghub.us',

    'davystore.com',
    'trixieboutiques.com', // 21/5
    'tyracharm.com',
    'mellycharm.com',
    'lovejessiebra.com'
  )
}

export const isDomainStaticPageSimpleMarketing = (domain?: string) => {
  return isOneOf(
    domain || (process.browser ? window?.location?.hostname : ''),
    'quantity.onselless.dev',
    'leilawear.com',
    'sheluxes.com',
    'julidostore.us', //
    'avacharm.com', //

    'beeflower.us',
    'kamiluxe.com',
    'fionacharm.com',
    'dollixmas.com'
  )
}
export const isDomainStaticKYC = (domain?: string) => {
  return isOneOf(domain || (process.browser ? window?.location?.hostname : ''), 'luxrosy.com')
}

export const isShowVideo = (domain?: string) => {
  return isOneOf(
    domain || (process.browser ? window?.location?.hostname : ''),
    // 'bestflexpants.com',
    // 'flexstridespants.com',
    // 'flexstrides.com',
    // 'mycloverapparel.com'
    'testshop.onselless.dev',
    'noerror.onselless.dev'
  )
}

export const isActiveSpeedCurve = (domain?: string) => {
  return isOneOf(
    domain || (process.browser ? window?.location?.hostname : ''),
    'v2multiple.onselless.com',
    'v2exsecret.onselless.com',
    'bragoddess.com',
    'exsecret.com'
  )
}

export const isActiveVariant = (domain?: string) => {
  return isOneOf(
    domain || (process.browser ? window?.location?.hostname : ''),
    'v2testing.onselless.dev',
    'luxrosy.com',
    'nabibras.com',
    'lucianasecret.com'
  )
}

export const isLogoBigV2 = (domain?: string) => {
  return isOneOf(
    domain || (process.browser ? window?.location?.hostname : ''),
    'v2testing.onselless.dev',
    'lucianasecret.com'
  )
}

export const isActiveV21 = (domain?: string) => {
  return isOneOf(
    domain || (process.browser ? window?.location?.hostname : ''),
    'v2testing.onselless.dev',
    'luxrosy.com',
    'nabibras.com'
  )
}

export const hasBackground = (domain?: string) => {
  return isOneOf(
    domain || (process.browser ? window?.location?.hostname : ''),
    'v2testing.onselless.dev',
    'nabibras.com',
    'lucianasecret.com'
  )
}
